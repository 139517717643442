import React, { useRef, useState } from 'react';
import { Table, TableProps, Layout, Button, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';

import { handleAPIError, IFetchQuery } from '@services/base';
import { getUsers, IUser } from '@services/user';
import ActionCell from './components/ActionCell';
import { Plus } from 'lucide-react';
import useModal, { IModalPayload } from '@hooks/use-modal';

const { Content } = Layout;

const Widget: React.FC = () => {
  const [query, setQuery] = useState<IFetchQuery>({
    page: 1,
    page_size: 100,
  });
  const refContainer = useRef<HTMLDivElement>(null);
  const [, addUser] = useModal<IModalPayload<any>>('add-user');

  const [data, setData] = useState<IUser[]>([]);

  const [pagination, setPagination] = useState<any>({
    current: 1,
    total: 0,
    pageSize: 100,
    showSizeChanger: false,
    position: ['bottomCenter'],
  });

  const columns: ColumnsType<IUser> = [
    {
      title: 'Name',
      key: 'displayName',
      dataIndex: 'displayName',
      sorter: (a, b) => {
        const value1 = a.displayName || '';
        const value2 = b.displayName || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => {
        const value1 = a.email || '';
        const value2 = b.email || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: 'Role',
      key: 'role',
      render: (data: IUser) => {
        return (
          <span>
            {data.permissions.map(p => {
              return <Tag key={p}>{p}</Tag>;
            })}
          </span>
        );
      },
      sorter: (a, b) => {
        const value1 = a.permissions[0] || '';
        const value2 = b.permissions[0] || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 100,
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 20,
      render: (data: IUser) => {
        return <ActionCell data={data} />;
      },
    },
  ];

  const { isFetching } = useQuery(['users', query], () => getUsers(), {
    keepPreviousData: true,
    onSuccess: async res => {
      const users = res.users || [];

      setData(users);
    },
    onError: handleAPIError,
  });

  const onChangeTable: TableProps<IUser>['onChange'] = async (
    pag,
    filters,
    sorters,
  ): Promise<void> => {
    let newQuery: IFetchQuery = {
      page: pag.current,
      page_size: 100,
    };

    const { order, columnKey } = sorters as SorterResult<IUser>;
    if (order) {
      newQuery = {
        ...newQuery,
        page_size: 100,
        order_by: columnKey,
        order_direction: order,
      };
    }
    if (pag.current !== pagination.current) {
      newQuery = { ...newQuery, page: pag.current, page_size: 100 };

      setPagination({
        ...pagination,
        current: pag.current,
        page_size: 100,
      });
    }
    setQuery(newQuery);
  };

  return (
    <>
      <Content
        className={'bg-white'}
        style={{ borderRadius: '15px', overflow: 'hidden', padding: '10px' }}
      >
        <Button
          onClick={() =>
            addUser({ isOpen: true, payload: { editing: false, data: null } })
          }
          type="primary"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Plus size={15} /> <span>Add user</span>
        </Button>
        <div ref={refContainer} style={{ height: '96%', marginTop: '5px' }}>
          <Table
            loading={isFetching}
            onChange={onChangeTable}
            columns={columns}
            pagination={pagination}
            rowKey="_id"
            dataSource={data}
            scroll={
              refContainer?.current
                ? {
                    y: refContainer.current.clientHeight - 56 - 56,
                    x: refContainer.current.clientWidth,
                  }
                : undefined
            }
          />
        </div>
      </Content>
    </>
  );
};

export default Widget;

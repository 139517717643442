import { apiRequest, IFetchQuery, IResponse } from '@services/base';

import { IPenalty, IPenaltyResult, IStatus } from './penalty.model';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const apiPrefix = process.env.REACT_APP_API_PATH_PREFIX;
const exportMicroservicePrefix = process.env.REACT_APP_EXPORT_PATH_PREFIX;
const statsMicroservicePrefix = process.env.REACT_APP_STATS_PATH_PREFIX;
const undoBillingMicroservicePrefix =
  process.env.REACT_APP_UNDO_BILLING_PATH_PREFIX;
const importNoShowMicroservicePrefix =
  process.env.REACT_APP_IMPORT_NOSHOW_PATH_PREFIX;
const getAttachmentMicroservicePrefix =
  process.env.REACT_APP_GET_ATTACHMENT_PATH_PREFIX;
const uploadFileMicroservicePrefix =
  process.env.REACT_APP_UPLOAD_FILE_PATH_PREFIX;
const baseUrl = process.env.REACT_APP_API_URL;
export const getPenalties = async (query: IFetchQuery) => {
  console.log('query', query);
  const queryString = new URLSearchParams(query).toString();
  const res = await apiRequest.get<IResponse & { penalties: IPenalty[] }>(
    `${apiPrefix}/penalties?${queryString}`,
  );

  res?.penalties?.forEach((penalty: IPenalty) => {
    const { order_date } = penalty;
    if (order_date) {
      let orderDate = new Date(order_date);
      let d: any = new Date(
        Date.UTC(
          orderDate.getFullYear(),
          orderDate.getMonth(),
          orderDate.getDate(),
        ),
      );
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      penalty.week = penalty.week || weekNo;
    }
  });

  if (query.ids?.length > 0) {
    return {
      ...res,
      penalties: res.penalties.filter(({ _id }) => query.ids.indexOf(_id) >= 0),
    };
  }

  return res;
};

export const getPenalty = async (id: string) => {
  return apiRequest.get<IResponse & { penalty: IPenalty }>(
    `${apiPrefix}/penalties/${id}`,
  );
};

export const updatePenalties = async (body: IFetchQuery[]) => {
  return apiRequest.put<IPenaltyResult[]>(
    `${apiPrefix}/penalties/bulk-actions`,
    body,
  );
};

export const updatePenalty = async (id: string, body: any) => {
  return apiRequest.put<IPenaltyResult[]>(`${apiPrefix}/penalties/${id}`, body);
};

export const importPenalties = async (body: IFetchQuery[]) => {
  return apiRequest.post<IPenaltyResult[]>(
    `${apiPrefix}/penalties/import`,
    body,
  );
};

export const importNormalPenalties = async (body: IFetchQuery[]) => {
  return apiRequest.post<IPenaltyResult[]>(`${baseUrl}/importPenalties`, body);
};

export const createPenalties = async (body: IFetchQuery[]) => {
  return apiRequest.post<IPenaltyResult[]>(
    `${apiPrefix}/penalties/bulk-actions`,
    body,
  );
};

export const deletePenalties = async (body: string[]) => {
  return apiRequest.del<IPenaltyResult[]>(
    `${apiPrefix}/penalties/bulk-actions`,
    body,
  );
};

export const getNoShowImportFields = (): string[] => {
  return [
    'customer_reference',
    'order_date',
    'line_code',
    'competence',
    'penalty_percentage',
    'total_net_cost',
    'total_net_price',
    'tour_composition',
    'route_composition',
  ];
};

export const getPenaltyImportFields = (): string[] => {
  return [
    'customer_reference',
    'order_date',
    'line_code',
    'competence',
    'penalty_percentage',
    'tour_composition',
    'route_composition',
    'planned_departure_at',
    'actual_departure_at',
    'planned_arrival_at',
    'actual_arrival_at',
    'delay_minutes',
    'delay_type',
  ];
};
export const getNoShowPenaltyImportFields = (): string[] => {
  return [
    'week',
    'sennder_reference',
    'customer_reference',
    'carrier_id',
    'carrier_name',
    'status',
    'line_code',
    'order_date',
    'received_on',
    'delay_type',
    'tour_composition',
    'route_composition',
    'initial_penalty_percentage',
    'penalty_percentage',
    'sda_penalty_cost',
    'total_net_cost',
    'tags',
  ];
};

export const getSafetyPenaltyImportFields = (): string[] => {
  return [
    'week',
    'sennder_reference',
    'customer_reference',
    'carrier_id',
    'carrier_name',
    'status',
    'line_code',
    'order_date',
    'received_on',
    'delay_type',
    'tour_composition',
    'route_composition',
    'tags',
    'processing_category',
    'trailer_plate',
    'penalty_amount',
  ];
};

export const getExportingFields = (): string[] => {
  return [
    '_id',
    'week',
    'sennder_reference',
    'customer_reference',
    'carrier_id',
    'carrier_name',
    'status',
    'line_code',
    'order_date',
    'received_on',
    'delay_type',
    'gps_tracked',
    'created_at',
    'justified_at',
    'billed_at',
    'expiration_date',
    'initial_delay_minutes',
    'delay_minutes',
    'tour_composition',
    'tour_km',
    'route_composition',
    'route_km',
    'initial_penalty_percentage',
    'penalty_percentage',
    'sda_penalty_cost',
    'total_net_price',
    'total_net_cost',
    'integration_request_body',
    'processing_category',
    'processing_body',
    'planned_departure_at',
    'actual_departure_at.timestamp',
    'actual_departure_at.source',
    'planned_arrival_at',
    'actual_arrival_at.timestamp',
    'actual_arrival_at.source',
    'tags',
    'justification.category',
    'justification.body',
    'justification.attachments_url',
    'thread.id',
    'thread.subscribers',
    'truck_plate',
    'trailer_plate',
  ];
};

export const billPenalties = (ids: string[]) => {
  return apiRequest.post<IPenaltyResult[]>(`${apiPrefix}/penalties/bill`, {
    ids,
  });
};

export const bulkEdit = (
  filters: any,
  billableStatus: string,
): Promise<any> => {
  console.log('filters', filters);
  const queryString = new URLSearchParams(filters).toString();
  return apiRequest.post<IPenaltyResult[]>(`/bulkEdit?${queryString}`, {
    status: billableStatus,
  });
};

export const exportPenalties = async (query: IFetchQuery): Promise<any> => {
  const queryString = new URLSearchParams(query).toString();
  return apiRequest.get(`${exportMicroservicePrefix}?${queryString}`);
};

export const getAttachment = async (url: string): Promise<any> => {
  return apiRequest.get(`${getAttachmentMicroservicePrefix}?url=${url}`);
};

export const uploadInternalFile = async (
  sennderRef: string,
  competence: string,
  fileType: string,
  file: any,
): Promise<any> => {
  const formData = new FormData();
  formData.append('sennder_ref', sennderRef);
  formData.append('file_type', fileType);
  formData.append('competence', competence);
  formData.append('file', file.originFileObj as File);
  return axios.post(`${apiUrl}${uploadFileMicroservicePrefix}`, formData, {
    headers: { 'x-auth-token': window.localStorage.token },
  });
};

export const getStats = async (query?: IFetchQuery): Promise<any> => {
  const queryString = query ? new URLSearchParams(query).toString() : '';
  return await apiRequest.get<IResponse & { penalties: IPenalty[] }>(
    `${statsMicroservicePrefix}?${queryString}`,
  );
};

export const undoBilling = async (ids: string[]): Promise<any> => {
  return await apiRequest.get(
    `${undoBillingMicroservicePrefix}?invoice_number=${ids?.join(',')}`,
  );
};

export const importNoShow = async (body: IFetchQuery[]) => {
  return apiRequest.post<IPenaltyResult[]>(
    `${importNoShowMicroservicePrefix}/`,
    body,
  );
};

export const importSafety = async (body: IFetchQuery[]) => {
  return apiRequest.post<IPenaltyResult[]>(`/importSafety/`, body);
};

export type PenaltyAttachmentType =
  | 'FDC'
  | 'EMAIL_SENNDER'
  | 'SHIPOWNER_COMMUNICATION'
  | 'POLICE_REPORT'
  | 'TRAFFIC_EVIDENCE'
  | 'TRAFFIC_PHOTO'
  | 'GPS'
  | 'GPS_GRAPH'
  | 'LANE_HISTORY'
  | 'REPAIR_REPORT'
  | 'INVOICE'
  | 'INCIDENT_PHOTO'
  | 'OTHER';

export enum PenaltyAttachmentLabel {
  FDC = 'Foglio di corsa',
  EMAIL_SENNDER = 'Email a operativo sennder',
  SHIPOWNER_COMMUNICATION = "Comunicazione ufficiale dell'armatore",
  POLICE_REPORT = 'Verbale di polizia',
  TRAFFIC_EVIDENCE = 'Segnalazione ufficiale del traffico sul portale delle società stradali e autostradali',
  TRAFFIC_PHOTO = 'Prova di traffico tangibile (es. foto del traffico)',
  GPS = 'Tracciato GPS tabellare del mezzo',
  GPS_GRAPH = 'Tracciato GPS grafico',
  LANE_HISTORY = 'Storicità dei transiti sulla linea delle 3 settimane precedenti',
  REPAIR_REPORT = "Verbale dell'officina",
  INVOICE = 'Fattura/ricevuta',
  INCIDENT_PHOTO = 'Evidenza fotografica del sinistro',
  OTHER = 'Altro',
}

export const getStatuses = async (): Promise<
  IResponse & { data: IStatus[] }
> => {
  return {
    data: [
      {
        code: 'APPLIED',
        name: 'Applied',
        color: 'error',
      },
      {
        code: 'PENDING',
        name: 'Pending',
        color: 'processing',
      },
      {
        code: 'NOT_APPLIED',
        name: 'Not Applied',
        color: 'success',
      },
      {
        code: 'BILLABLE',
        name: 'Billable',
        color: 'default',
      },
      {
        code: 'BILLED',
        name: 'Billed',
        color: 'default',
      },
      {
        code: 'TO_REVIEW',
        name: 'To Review',
        color: 'default',
      },
      // {
      //   code: 'ARCHIVED',
      //   name: 'Archived',
      //   color: 'default',
      // },
    ],
  };
};

import React from 'react';
import { Popover, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';

import {
  getSafetyPenaltyImportFields,
  ISafetyPenaltyImport,
} from '@services/penalty';
import { CircleCheck, TriangleAlert } from 'lucide-react';

const { Text } = Typography;

interface IProps {
  data: ISafetyPenaltyImport[];
}

const Preview = (props: IProps) => {
  const { data } = props;
  const fields = getSafetyPenaltyImportFields();

  const columns: ColumnsType<ISafetyPenaltyImport> = [
    {
      key: 'rowStatus',
      width: 64,
      fixed: 'left',
      align: 'center',
      render: (data: ISafetyPenaltyImport) => {
        if (data.errors?.length) {
          const content = (
            <Space direction={'vertical'}>
              {data.errors.map(({ message, column }) => (
                <Text type="danger" key={column}>
                  {message}
                </Text>
              ))}
            </Space>
          );
          return (
            <Popover content={content}>
              <Text type="danger">
                <TriangleAlert
                  size={14}
                  style={{ fontSize: '20px' }}
                  color="red"
                />
              </Text>
            </Popover>
          );
        }
        return (
          <Popover content="Ready to Import">
            <Text type="success">
              <CircleCheck size={14} style={{ fontSize: '20px' }} />
            </Text>
          </Popover>
        );
      },
    },
    ...fields.map(key => {
      return {
        key,
        dataIndex: key,
        title: key,
        width: 150 + key.length * 2,
      };
    }),
  ];

  return (
    <Table
      size={'small'}
      bordered
      columns={columns}
      rowKey="sennder_reference"
      dataSource={data}
      pagination={false}
      scroll={{ y: 200, x: 300 }}
    />
  );
};

export default Preview;

import useModal, { IModalPayload } from '@hooks/use-modal';
import { bulkEdit } from '@services/penalty';
import { Button, Modal, notification } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { handleAPIError } from '@services/base';

function SetBillableModal() {
  const [modal, setModal] = useModal<IModalPayload<any>>('billable-modal');
  const filters = modal?.payload || {};
  console.log('filters', modal);
  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  const queryClient = useQueryClient();
  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: { filters: any; billableStatus: string }) => {
      return bulkEdit(payload.filters, payload.billableStatus);
    },
    {
      onSuccess: async res => {
        onClose();
        await queryClient.invalidateQueries('penalties');
        if (!res.error) {
          notification.success({
            message: 'Success',
            description: 'Penalties status updated successfully',
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Cannot update penalties status',
          });
        }
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    const payload = {
      filters,
      billableStatus: 'BILLABLE',
    };
    mutate(payload);
  };

  return (
    <Modal
      onCancel={onClose}
      title="Set Billable"
      centered
      visible={modal?.isOpen || false}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={submit}
          loading={isSubmitting}
        >
          Confirm
        </Button>,
      ]}
    >
      <p>Are you sure you want to set the selected penalties as billable?</p>
    </Modal>
  );
}

export default SetBillableModal;

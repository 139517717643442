import React, { useState } from 'react';
import { Layout, notification } from 'antd';
import GridFilter from './components/GridFilter';
import {
  downloadInvoices,
  getStats,
  downloadFinanceInvoices,
} from '@services/invoice';
import { IInvoiceFilter } from '@services/invoice/invoice.model';
import Stats from './components/Stats';
import DocumentsTable from './components/DocumentsTable';
import PenaltiesTable from './components/PenaltiesTable';
import ViewModeButtons from './components/ViewModeButtons';
import { useQuery } from 'react-query';
import { IFetchQuery } from '@services/base';
import moment from 'moment';
import { formatMomentRange } from '@utils/utilities';

const { Content } = Layout;

const Widget: React.FC = () => {
  const [query, setQuery] = useState<IFetchQuery>({ page: 1, page_size: 100 });
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<'documents' | 'penalty'>(
    'documents',
  );

  const currentYear = 2025; //moment().year();

  const defaultDateRange = [
    moment(`${currentYear}-01-01 00:00`),
    moment(`${currentYear}-12-31 23:59`),
  ];

  const [filter, setFilter] = useState<IInvoiceFilter>({
    invoice_number: '',
    type: '',
    emission_range: defaultDateRange,
    competence_range: '',
    carrier_id: '',
    sennder_reference: '',
    customer_reference: '',
    tags: [],
    status: '',
  });
  const [stats, setStats] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onCsvDownload = async () => {
    try {
      const response = await downloadInvoices(filter, viewMode);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoices.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      notification.error({
        message: 'Error downloading invoices',
      });
    }
  };

  const onFinanceCsvDownload = async () => {
    try {
      const response = await downloadFinanceInvoices(filter, viewMode);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoices.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      notification.error({
        message: 'Error downloading invoices',
      });
    }
  };

  const onFilter = async (values: IInvoiceFilter) => {
    values.competence_range = formatMomentRange(values.competence_range);
    values.emission_range = formatMomentRange(values.emission_range);

    setQuery({ ...query, page: 1, page_size: 100 });
    setFilter(values);
  };

  const { isFetching } = useQuery(['stats', filter], () => getStats(filter), {
    onSuccess: data => setStats(data),
    onError: () => {
      notification.error({
        message: 'Error fetching stats',
      });
    },
  });

  const handleViewModeChange = (mode: 'documents' | 'penalty') => {
    setViewMode(mode);
    setQuery({ page: 1, page_size: 100 });
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
      }}
    >
      <Stats isLoading={isFetching} data={stats} />
      <Content
        className={'bg-white'}
        style={{
          borderRadius: '15px',
          overflow: 'hidden',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 200px)', // Add fixed height
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <GridFilter
            data={filter}
            onFilter={onFilter}
            onCsvDownload={onCsvDownload}
            onCsvFinanceDownload={onFinanceCsvDownload}
          />
          <ViewModeButtons
            viewMode={viewMode}
            setViewMode={handleViewModeChange}
            loading={isLoading}
          />
        </div>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {viewMode === 'documents' ? (
            <DocumentsTable
              viewMode={viewMode}
              query={query}
              setQuery={setQuery}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              filter={filter}
              processingIds={processingIds}
              setProcessingIds={setProcessingIds}
              setStats={setStats}
              setLoading={setLoading}
            />
          ) : (
            <PenaltiesTable
              viewMode={viewMode}
              query={query}
              setQuery={setQuery}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              filter={filter}
              processingIds={processingIds}
              setProcessingIds={setProcessingIds}
              setStats={setStats}
              setLoading={setLoading}
            />
          )}
        </div>
      </Content>
    </div>
  );
};

export default Widget;

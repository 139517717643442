import React, { useState } from 'react';
import { Button, Modal, notification, Space, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import isDate from 'date-fns/isDate';
import parse from 'date-fns/parse';
import { unflatten } from 'flat';

import {
  IPenalty,
  IPenaltyError,
  IPenaltyResult,
  importSafety,
  SafetyPenaltySchema,
  ISafetyPenaltyImport,
} from '@services/penalty';
import { handleAPIError, IFetchQuery } from '@services/base';
import useModal, { IModalPayload } from '@hooks/use-modal';

import Uploader from './components/Uploader';
import Preview from './components/Preview';
import { Import } from 'lucide-react';

const ImportCSVPenaltySafetyModal = () => {
  const queryClient = useQueryClient();
  const [modal, setModal] = useModal<IModalPayload<any>>(
    'import-penalty-safety-csv',
  );
  const [, openResultModal] =
    useModal<IModalPayload<IPenaltyResult[]>>('penalty-result');
  const [data, setData] = useState<ISafetyPenaltyImport[]>([]);

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
    setData([]);
  };

  const onParsed = (data: ISafetyPenaltyImport[]) => {
    const newData = data.map(item => {
      Object.keys(item).forEach(key => {
        const value = item[key as keyof ISafetyPenaltyImport];
        if (!value) {
          delete item[key as keyof ISafetyPenaltyImport];
        }
      });

      const errors: IPenaltyError[] = [];
      const { error } = SafetyPenaltySchema.validate(item, {
        abortEarly: false,
      });
      if (error?.details?.length) {
        error.details.forEach(item => {
          errors.push({
            message: item.message,
            column: item.context?.key || '',
          });
        });
      }

      return {
        ...item,
        errors,
      } as ISafetyPenaltyImport;
    });

    setData(newData);
  };

  const submit = () => {
    try {
      mutate(
        data.map(item => {
          try {
            let newData: any = {
              ...item,
            };
            Object.keys(newData).forEach(key => {
              const value: any = item[key as keyof ISafetyPenaltyImport];
              const date = parse(value, 'MM/dd/yyyy HH:mm:ss', new Date());
              if (
                date &&
                (/_at$/.test(key) ||
                  /_date$/.test(key) ||
                  /_on$/.test(key) ||
                  /timestamp$/.test(key)) &&
                isDate(date)
              ) {
                console.log(newData['customer_reference'], date);
                newData[key as keyof IPenalty] = date.toISOString() as never;
              }
            });

            newData = unflatten(newData, { object: true });

            newData.tags = item.tags?.split(',') || [];

            return newData;
          } catch (error: any) {
            notification.error({
              message: `Failed to parse ${item.customer_reference} (${item.sennder_reference})`,
              description: error.message,
            });
            throw error;
          }
        }),
      );
    } catch (error: any) {
      console.error(error);
      notification.error({
        message:
          'There was an error parsing your CSV, please check the inserted data',
      });
    }
  };

  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: IFetchQuery[]) => {
      return importSafety(payload);
    },
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries('penalties');
        onClose();
        if (res.every(({ success }) => success)) {
          notification.success({
            message: 'Import penalty',
            description: 'Imported successfully',
          });
        } else {
          openResultModal({ isOpen: true, payload: res || [] });
        }
      },
      onError: handleAPIError,
    },
  );

  const isValid = !data.some(({ errors }) => errors?.length);

  return (
    <Modal
      destroyOnClose
      centered
      width={720}
      onCancel={onClose}
      footer={null}
      title={
        <>
          <Import size={14} className={'mr-2'} />
          Import Safety Penalties CSV
          {data?.length ? <span className="ml-1">({data.length})</span> : null}
        </>
      }
      visible={modal?.isOpen || false}
    >
      {data?.length ? (
        <Spin spinning={isSubmitting}>
          <div style={{ maxHeight: '280px', overflow: 'hidden' }}>
            <Preview data={data} />
          </div>
          <br />
          <div className={'text-center'}>
            <Space align={'center'}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                onClick={submit}
                disabled={data.length === 0 || !isValid}
                type="primary"
                icon={<Import size={14} />}
              >
                Import
              </Button>
            </Space>
          </div>
        </Spin>
      ) : (
        <Uploader onParsed={onParsed} />
      )}
    </Modal>
  );
};

export default ImportCSVPenaltySafetyModal;

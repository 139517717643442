import React from 'react';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tag,
} from 'antd';
import { useQuery } from 'react-query';
import useModal from '@hooks/use-modal';

import {
  getStatuses,
  IPenaltyFilter,
  IStatus,
  PenaltyJustificationCategory,
} from '@services/penalty';
import { getCarriers } from '@services/carrier';

import styles from './grid-filter.module.scss';
import isArray from 'lodash/isArray';
import { formatDate } from '@utils/utilities';
import { Filter } from 'lucide-react';
import useAuth from '@hooks/use-auth';

interface IProps {
  data: IPenaltyFilter;
  onFilter: (values: IPenaltyFilter) => void;
  onCsvDownload: (values: IPenaltyFilter) => void;
}

const GridFilter = (props: IProps): JSX.Element => {
  let authSession = useAuth();
  const authData = authSession.data;
  const userPermissions = authData?.flatPermissions;
  console.log(userPermissions);
  const [, openModal] = useModal('billable-modal');
  const { onFilter, data, onCsvDownload } = props;
  const [form] = Form.useForm();

  const { data: statuses } = useQuery(
    'penalty-status',
    () => getStatuses(),
    {},
  );

  const { data: carriers } = useQuery('carriers', () => getCarriers(), {});

  const delayTypes: any = [
    'Transit',
    'Positioning',
    'No Show',
    'Departure',
    'Safety',
  ];

  const justificationCategories = [
    {
      value: 'EMPTY_VEHICLE',
      label: PenaltyJustificationCategory.EMPTY_VEHICLE,
    },
    {
      value: 'WRONG_DOCUMENT',
      label: PenaltyJustificationCategory.WRONG_DOCUMENT,
    },
    {
      value: 'BROKEN_VEHICLE',
      label: PenaltyJustificationCategory.BROKEN_VEHICLE,
    },
    {
      value: 'TRAVEL_TIME',
      label: PenaltyJustificationCategory.TRAVEL_TIME,
    },
    {
      value: 'HUB_DELAY',
      label: PenaltyJustificationCategory.HUB_DELAY,
    },
    {
      value: 'TRAFFIC',
      label: PenaltyJustificationCategory.TRAFFIC,
    },
    { value: 'FERRY_DELAY', label: PenaltyJustificationCategory.FERRY_DELAY },
    {
      value: 'VEHICLE_ACCIDENT',
      label: PenaltyJustificationCategory.VEHICLE_ACCIDENT,
    },
    { value: 'POLICE_STOP', label: PenaltyJustificationCategory.POLICE_STOP },
    {
      value: 'HUB_OPENING',
      label: PenaltyJustificationCategory.HUB_OPENING,
    },
    {
      value: 'OTHER',
      label: PenaltyJustificationCategory.OTHER,
    },
  ];
  const uniqueCarrierIds = new Set();
  const uniqueCarriers = carriers?.carriers
    ?.filter(item => {
      if (!uniqueCarrierIds.has(item.carrier_id)) {
        uniqueCarrierIds.add(item.carrier_id);
        return true;
      }
      return false;
    })
    .map(item => ({
      label: item.carrier_name,
      value: item.carrier_id,
    }));

  const filters = [
    {
      label: 'Sennder reference',
      field: 'sennder_reference',
      type: 'text',
    },
    {
      label: 'Customer reference',
      field: 'customer_reference',
      type: 'text',
    },
    {
      label: 'Line Code',
      field: 'line_code',
      type: 'text',
    },
    {
      label: 'Route Composition',
      field: 'route_composition',
      type: 'text',
    },
    {
      label: 'Carrier',
      field: 'carrier_id',
      type: 'select',
      mode: 'multiple',
      values: uniqueCarriers || [],
    },
    {
      label: 'Truck Plate',
      field: 'truck_plate',
      type: 'text',
    },

    {
      label: 'Trailer Plate',
      field: 'trailer_plate',
      type: 'text',
    },
    {
      label: 'Tags',
      field: 'tags',
      type: 'tags',
    },

    {
      label: 'Status',
      field: 'status',
      type: 'select',
      mode: 'multiple',
      values:
        statuses?.data?.map((item: IStatus) => {
          return {
            label: item.name,
            value: item.code,
          };
        }) || [],
    },
    {
      label: 'Justification',
      field: 'justification_body',
      type: 'text',
    },
    {
      label: 'Justification Category',
      field: 'justification_category',
      type: 'select',
      values: justificationCategories,
    },
    {
      label: 'Penalty Type',
      field: 'delay_type',
      type: 'select',
      values:
        delayTypes?.map((item: any) => {
          return {
            label: item,
            value: item,
          };
        }) || [],
    },
    {
      label: 'Order Date Range',
      field: 'order_date_range',
      type: 'range-picker',
    },
    {
      label: 'Week',
      field: 'week',
      type: 'text',
    },
    {
      label: 'Attachment',
      field: 'has_attachment',
      type: 'radio',
      values: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      label: 'Internal Files',
      field: 'has_internal_files',
      type: 'radio',
      values: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      label: 'GPS Tracked',
      field: 'gps_tracked',
      type: 'radio',
      values: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    /*
    {
      label: 'Comments',
      field: 'has_comment',
      type: 'radio',
      values: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    */
  ];

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  const onSubmit = (values: IPenaltyFilter) => {
    onFilter(values);
  };

  const onCsvExport = (values: IPenaltyFilter) => {
    onCsvDownload(values);
  };

  const content = (
    <div className={styles.filterDropdown} style={{ width: '90%' }}>
      <Form
        layout="vertical"
        form={form}
        initialValues={
          data.order_date_range
            ? { order_date_range: data.order_date_range }
            : {}
        }
        onFinish={onSubmit}
      >
        <div onClick={e => e.stopPropagation()}>
          <Row gutter={12}>
            {filters.map(item => {
              return (
                <Col key={item.field} span={12}>
                  {item.type === 'number' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Input autoComplete="off" type="number" />
                    </Form.Item>
                  ) : null}
                  {item.type === 'text' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Input autoComplete="off" />
                    </Form.Item>
                  ) : null}
                  {item.type === 'select' && item.values ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Select
                        showSearch={item.values.length > 5}
                        allowClear
                        {...(item.mode ? { mode: 'multiple' } : {})}
                        virtual={false}
                        filterOption={(
                          inputValue,
                          option, //Filter label instead of value since the value is the carrier ID
                        ) =>
                          (option?.children as unknown as string)
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toString().toLowerCase())
                        }
                      >
                        {item.values.map(({ label, value }: any) => {
                          return (
                            <Select.Option key={value} value={value}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {item.type === 'tags' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Select
                        mode="tags"
                        showSearch={false}
                        notFoundContent={'Enter the tags'}
                      />
                    </Form.Item>
                  ) : null}
                  {item.type === 'radio' && item.values ? (
                    <Form.Item label={item.label} name={item.field}>
                      <Radio.Group>
                        {item.values.map(({ label, value }: any) => {
                          return (
                            <Radio.Button key={value} value={value}>
                              {label}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>
                  ) : null}
                  {item.type === 'range-picker' ? (
                    <Form.Item label={item.label} name={item.field}>
                      <DatePicker.RangePicker format="YYYY-MM-DD" />
                    </Form.Item>
                  ) : null}
                </Col>
              );
            })}
          </Row>
        </div>
        <Row gutter={12}>
          <Col span={6}>
            <Button block onClick={onReset}>
              Reset
            </Button>
          </Col>
          <Col span={6}>
            <Button type="ghost" block onClick={() => onCsvExport(data)}>
              Export CSV
            </Button>
          </Col>

          {(userPermissions?.manager || userPermissions?.superuser) && (
            <Col span={6}>
              <Button
                type="ghost"
                block
                onClick={() => openModal({ isOpen: true, payload: data })}
              >
                Add Billable
              </Button>
            </Col>
          )}
          <Col span={6}>
            <Button type="primary" block htmlType="submit">
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  let counter = 0;
  const keys = Object.keys(data);

  keys.forEach(key => {
    const value = data[key as keyof IPenaltyFilter];
    if (value !== undefined && value !== '') {
      counter = counter + 1;
    }
  });

  const removeTag = (key: string) => {
    return () => {
      form.setFieldsValue({
        ...data,
        [key]: undefined,
      });
      form.submit();
      onFilter({ ...data, [key]: undefined });
    };
  };

  return (
    <div className={styles.filter}>
      <Space>
        <Dropdown overlay={content} trigger={['click']}>
          <div className="mr-3">
            <Badge count={counter}>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '4px 8px',
                }}
              >
                <Filter size={14} />
                <span>&nbsp; Filter</span>
              </Button>
            </Badge>
          </div>
        </Dropdown>

        {filters.map(({ type, label, field, values }) => {
          const value = data[field as keyof IPenaltyFilter];
          if (value) {
            return (
              <Tag
                onClose={removeTag(field)}
                closable
                key={field}
                style={{ padding: '4px 8px' }}
              >
                <strong>{label}: </strong>
                {type === 'number' ? value : null}
                {type === 'text' ? value : null}
                {(type === 'select' &&
                  !isArray(value) &&
                  values?.find((item: any) => item.value === value)?.label) ||
                  null}
                {(type === 'select' &&
                  isArray(value) &&
                  value
                    .map(
                      child =>
                        values?.find((item: any) => item.value === child)
                          ?.label,
                    )
                    .join(', ')) ||
                  null}
                {type === 'tags' && isArray(value) ? value.join(', ') : null}
                {type === 'range-picker'
                  ? `${formatDate(
                      value.toString().split(',')[0],
                    )} - ${formatDate(value.toString().split(',')[1])}`
                  : null}
                {type === 'radio' &&
                  value !== null &&
                  value !== undefined &&
                  values?.find((item: any) => item.value === value)?.label}
              </Tag>
            );
          }
          return null;
        })}
      </Space>
    </div>
  );
};

export default GridFilter;

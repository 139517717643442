import React from 'react';
import { Menu, Layout, Spin, MenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import logoShort from '@assets/images/logo-short.svg';
import logoFull from '@assets/images/logo-full.svg';

import useAuth from '@hooks/use-auth';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { useSidebar } from '@contexts/SidebarContext';
import {
  ArrowLeftToLine,
  ArrowRightFromLine,
  BadgeEuro,
  CloudUpload,
  FileInput,
  FileUp,
  Route,
  Truck,
  Users,
} from 'lucide-react';

const { Sider } = Layout;

const AppMenu: React.FC = () => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = React.useState(
    location.pathname === '/' ? 'home' : location.pathname.split('/')[1],
  );
  let { data } = useAuth();
  const { collapsed, toggleCollapsed } = useSidebar();
  const [, importCSV] = useModal<IModalPayload<any>>('import-csv');
  const [, importNoShowCsv] = useModal<IModalPayload<any>>('import-noshow-csv');
  const [, importPenaltySafetyCSV] = useModal<IModalPayload<any>>(
    'import-penalty-safety-csv',
  );
  const [, uploadInternalFiles] = useModal<
    IModalPayload<{ sennderRef: string | null; competence?: string | null }>
  >('upload-internal-files');

  const { flatPermissions = {} } = data || {};

  const mainMenuItems = [
    {
      key: 'home',
      label: 'Penalty Section',
      icon: <Route size={16} />,
      onClick: () => {
        setSelectedKey('home');
        window.location.href = '/';
      },
    },
    {
      key: 'financial',
      label: 'Financial Section',
      icon: <BadgeEuro size={16} />,
      onClick: () => {
        setSelectedKey('financial');
        window.location.href = '/financial';
      },
    },
    {
      key: 'carriers',
      label: 'Manage carriers',
      icon: <Truck size={16} />,
      permissions: ['manager', 'superuser', 'edit', 'view'],
      onClick: () => {
        setSelectedKey('carriers');
        window.location.href = '/carriers';
      },
    },
    {
      key: 'team',
      label: 'Manage team',
      icon: <Users size={16} />,
      permissions: ['manager', 'superuser'],
      onClick: () => {
        setSelectedKey('team');
        window.location.href = '/team';
      },
    },
  ].filter(item => {
    const { permissions = [] } = item;
    return permissions.length
      ? permissions.some(role => flatPermissions[role])
      : true;
  });

  const actionMenuItems = [
    {
      key: 'import-csv',
      label: 'Import CSV',
      icon: <FileUp size={16} />,
      permissions: ['edit', 'superuser', 'manager'],
      onClick: () => {
        importCSV({ isOpen: true, payload: null });
      },
    },
    {
      key: 'upload-files',
      label: 'Upload files',
      icon: <CloudUpload size={16} />,
      permissions: ['edit', 'superuser', 'manager'],
      onClick: () => {
        uploadInternalFiles({
          isOpen: true,
          payload: { sennderRef: null, competence: null },
        });
      },
    },
    {
      key: 'import-noshow-csv',
      label: 'Import NO SHOW CSV',
      icon: <FileInput size={16} />,
      permissions: ['edit', 'superuser', 'manager'],
      onClick: () => {
        importNoShowCsv({ isOpen: true, payload: null });
      },
    },
    {
      key: 'import-safety-csv',
      label: 'Import Safety CSV',
      icon: <FileInput size={16} />,
      permissions: ['edit', 'superuser', 'manager'],
      onClick: () => {
        importPenaltySafetyCSV({ isOpen: true, payload: null });
      },
    },
  ].filter(item => {
    const { permissions = [] } = item;
    return permissions.length
      ? permissions.some(role => flatPermissions[role])
      : true;
  });

  const menuItems: MenuProps['items'] = [
    {
      type: 'group',
      label: collapsed ? null : 'NAVIGATION',
      key: 'routes-group',
      children: mainMenuItems,
      style: {
        fontSize: '12px',
        color: '#666',
        fontWeight: 'bold',
        marginTop: '8px',
      },
    },
    {
      type: 'divider',
      style: { display: collapsed ? 'none' : 'block' },
    },
    {
      type: 'group',
      label: collapsed ? null : 'ACTIONS',
      key: 'actions-group',
      children: actionMenuItems,
      style: {
        fontSize: '12px',
        color: '#666',
        fontWeight: 'bold',
        marginTop: collapsed ? '0' : '16px',
      },
    },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: '#fff',
        borderRight: '2px solid #e8e8e8', // Changed to a light grey color
      }}
      width={250}
      trigger={
        <div
          style={{
            backgroundColor: '#fff',
            color: 'black',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            borderTop: '1px solid #f0f0f0',
          }}
        >
          {collapsed ? (
            <ArrowRightFromLine size={16} />
          ) : (
            <>
              <ArrowLeftToLine size={16} /> &nbsp; <span>COLLAPSE</span>
            </>
          )}
        </div>
      }
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{
            padding: '16px',
            borderBottom: '1px solid #f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '64px',
          }}
        >
          <img
            src={collapsed ? logoShort : logoFull}
            alt={'Sennder'}
            style={{
              maxWidth: '100%',
              maxHeight: '32px',
              objectFit: 'contain',
            }}
          />
        </div>
        <Spin spinning={false}>
          <Menu
            theme="light"
            mode="inline"
            items={menuItems}
            selectedKeys={[selectedKey]}
            style={{
              height: '100%',
              borderRight: 0,
              paddingTop: '8px',
            }}
            // Add custom styles for active menu items
            className="app-menu"
          />
        </Spin>
      </div>
    </Sider>
  );
};

export default AppMenu;
